/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Vidle from 'v-idle'
import moment from 'moment'


// import './plugins/click-away'

import './scss/app.scss';

Vue.use(Antd);
Vue.use(Vidle)
Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state:{
    featured: null,
    user: {},
    token: null,
    isUserLoggedIn: false,
    balance: 0,
    ledger: 0,

  },
  getters: {
    searchBar: (state) => {
      return state.searchBar;
    },
  },

  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
  },

  mutations: {
    hide(state) {
      state.searchBar = false
    },
    setToken (state, token) {
      state.token = token
      state.isUserLoggedIn = token && state.token != null;
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_BALANCE(state, payload) {
      state.balance = payload
    },
    SET_LEDGER(state, payload) {
      state.ledger = payload
    }

  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM Do YYYY, h:mm a')
  }
});

Vue.filter('formatDateDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM Do YYYY')
  }
});

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default store